import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { EventBus } from './eventBus.js'
let lan = localStorage.getItem('language')
import en from '../i18n/config/en.js'
import zh from '../i18n/config/zh.js'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 7000, // 请求超时时间
  withCredentials: true
})
// request拦截器
service.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem('token')
      if (token) {
        // config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['token'] =  token;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
    * code为非1是抛错
    **/
    const res = response.data
    if (res.code == 2) {
      Message({
        message: res.desc,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject('error')
    } else if (res.code == 4 || res.code == 10012 || res.code == 10017 || res.code == 102 || res.code == 6 || res.code == 10018 || res.code == 10019 || res.code == 10020 || res.code == 10021) {
      Message({
        message: res.desc,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject('error')
    } else if (res.code == 10086) {
      Message({
        message: res.desc,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject('error')
    } else if (res.code == 3) {
      Message({
        message: res.desc,
        type: 'error',
        duration: 5 * 1000
      })
    } else if (res.code == 5) {
      Message({
        message: res.desc,
        type: 'error',
        duration: 5 * 1000
      })
    } else if (res.code == 33) {
      Message({
        message: res.desc,
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    // 当响应异常时做处理
    if (error && error.response) {
      switch (error.response.status) {
        case 400: {
          // error.message = '请求错误(400)'
          Message({
            message: '请求错误',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 401: {
          EventBus.$emit('show-login-dialog');
          Message({
            message:lan == "en-US"?en.error[401]:zh.error[401] ,
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 403: {
          Message({
            message: '拒绝访问(',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 404: {
          Message({
            message: '请求出错',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 408: {
          Message({
            message: '请求超时',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 500: {
          Message({
            message: '',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 501: {
          Message({
            message: '服务未实现(501)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 502: {
          Message({
            message: '您的网络有问题，请稍等',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 503: {
          Message({
            message: '服务不可用(503)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 504: {
          Message({
            message: '网络超时(504)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        case 505: {
          Message({
            message: 'HTTP版本不受支持(505)',
            type: 'error',
            duration: 5 * 1000
          })
        } break
        default: {
          Message({
            message: `连接出错(${error.response.status})!`,
            type: 'error',
            duration: 5 * 1000
          })
        }
      }
    } else {
      Message({
        message: `连接服务器失败!`,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service

// export function post(url, params) {
//     console.log('params',params)
//   return new Promise((resolve, reject) => {
//     service
//       .post(url, params)
//       .then(res => {
//         resolve(res)
//       })
//       .catch(error => {
//         reject(error)
//       })
//   })
// }

// export function patchData(url, params) {
//   return new Promise((resolve, reject) => {
//     service
//       .patch(url, params)
//       .then(res => {
//         resolve(res)
//       })
//       .catch(error => {
//         reject(error)
//       })
//   })
// }

// export function deleteData(url, params) {
//   return new Promise((resolve, reject) => {
//     service
//       .delete(url, {
//         params: params
//       })
//       .then(res => {
//         resolve(res)
//       })
//       .catch(error => {
//         reject(error)
//       })
//   })
// }

// export function get(url, params) {
//   return new Promise((resolve, reject) => {
//     service
//       .get(url, {
//         params: params
//       })
//       .then(res => {
//         resolve(res)
//       })
//       .catch(error => {
//         reject(error)
//       })
//   })
// }