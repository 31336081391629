<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="640px"
      height="536px"
      :show-close="false"
    >
      <div class="loginContainer">
        <div class="login-header">
          <h1>
            {{ $t("common.welcome") }}
          </h1>
          <p>{{ $t("login.title") }}</p>
          <div class="exit" @click="exit">
            <i
              class="el-icon-close"
              style="font-size: 40px; color: #c1c1c1; font-weight: 300"
            ></i>
          </div>
        </div>
        <div class="login-main">
          <div style="margin-top: 0px; margin-bottom: 30px">
            <el-form
              :label-position="labelPosition"
              label-width="0px"
              :model="formLabelAlign"
            >
              <el-form-item
                :label="$t('login.email')"
                :class="isActive == 0 ? 'active' : ''"
              >
                <el-input
                  v-model="formLabelAlign.account"
                  :placeholder="$t('login.email')"
                  @blur="regEamil"
                  @focus="inputFocus(0)"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('login.password')"
                :class="isActive == 1 ? 'active' : ''"
              >
                <el-input
                  v-model="formLabelAlign.password"
                  show-password
                  :placeholder="$t('login.password')"
                  @focus="inputFocus(1)"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -20px;
            "
          >
            <el-checkbox v-model="checked" @click="RememberPsw">{{
              $t("login.Remember")
            }}</el-checkbox>
            <el-button type="text" @click="toFindpsw">{{ $t("login.findPsw") }}</el-button>
          </div>
          <div style="display: flex;justify-content: center;">
            <el-button type="primary" @click="loginAsync">{{
              $t("login.login")
            }}</el-button>
            <!-- <el-button @click="toRegister" size="medium">{{
            $t("login.findPsw")
          }}</el-button> -->
            <el-button
              @click="toRegister"
              style="border: solid 1px #628ba3; color: #628ba3"
              size="medium"
              >{{ $t("login.toRegister") }}</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="container">
      <div class="header">
        <div class="logo">
          <router-link to="/">
            <img
              src="../assets/images/logo.png"
              v-if="$i18n.locale == 'zh-CN'"
              alt=""
            />
            <img src="../assets/en_logo.png" v-else alt="" />
          </router-link>
        </div>
        <ul class="nav">
          <li>
            <router-link to="/search"> {{ $t("header.search") }}</router-link>
          </li>
          <li>
            <router-link to="/dataBase"> {{ $t("header.Name") }} </router-link>
          </li>
          <li>
            <router-link to="/upload"> {{ $t("header.upload") }} </router-link>
          </li>
          <li>
            <router-link to="/about"> {{ $t("header.About") }}</router-link>
          </li>
        </ul>

        <div class="internation">
          <div class="login">
            <div v-if="token == ''" @click="showLogin" class="logininner">
              {{ $t("login.login") + "/" + $t("login.register") }}
            </div>
            <div v-else class="logininner" @click.stop="toCenter">
              <span class="span1"> {{ userinfo?.nickname }} </span>|
              <span @click.stop="logout" style="white-space: nowrap">{{
                $t("search.exit")
              }}</span>
            </div>
          </div>
          <div class="earth">
            <img src="../assets/images/earth.png" alt="" />
            <div
              style="
                width: 80px;
                height: 110px;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
                background: #fff;
              "
              class="lng"
            >
              <div @click="changeLanguage('zh-CN')">中文</div>
              <div @click="changeLanguage('en-US')">English</div>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">
            <router-link to="/" style="color: #fff">
              <i class="el-icon-s-home" color="#fff"></i>
              {{ $t("header.home") }}</router-link
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: route }">
            <router-link :to="route" style="color: #fff">
              {{ $t(title) }}</router-link
            >
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>

<script>
const Base64 = require("js-base64").Base64;
import { login } from "@/Apis/index.js";
import { EventBus } from "@/utils/eventBus.js";
import { Debounce } from "@/utils/debounce.js";
export default {
  components: {},
  data() {
    return {
      isActive:0,
      labelPosition: "top",
      formLabelAlign: {
        account: "",
        password: "",
        type: "",
      },
      routers: [],

      token: "",
      cartNum: 0,
      title: "",
      dialogVisible: false,
      route: "",
      token: "",
      userinfo: {},
      checked:''
    };
  },
  computed: {},
  watch: {
    // 路由变化就是刷新数据，为了解决子级跳转到这个页面，数据不刷新问题
    $route: {
      handler() {
        this.handleDetail();
      },
      immediate: true,
    },
   
  },

  mounted () {
    let account = localStorage.getItem("account");
    if(account){
         this.formLabelAlign.account = localStorage.getItem('account')
      this.formLabelAlign.password = localStorage.getItem('password')// base64解密
      this.checked = true
    }
 
  },
  methods: {
    exit(){
      this.dialogVisible = false
      this.formLabelAlign = {
        account: "",
        password: "",
        type: "",
      }
    },
    RememberPsw(){
      console.log('123123')
      if(this.checked){
        localStorage.setItem('account',this.formLabelAlign.account)
        localStorage.setItem('password',this.formLabelAlign.password)
      }else{
        localStorage.removeItem('account')
        localStorage.removeItem('password')
      }
    },
    inputFocus(idx){
      this.isActive = idx
    },
    isObjectEmpty() {
      let keys = Object.keys(this.userinfo);
      return keys.length === 0;
    },
    showLogin() {
      this.dialogVisible = true;
    },
    logout() {
      localStorage.clear();
      this.userinfo = {};
      this.token = "";
      this.dialogVisible = true;
      setTimeout(() => {
            this.$router.go(0)
            }, 500);
    },
    toCenter() {
      this.$router.push({
        name: "history",
      });
    },
    regEamil() {
      const reg =
        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(this.formLabelAlign.account)) {
        this.formLabelAlign.account = "";
        return this.$message({
          message: this.$t("login.email_notice"),
          type: "warning",
        });
      }
    },
    loginAsync: Debounce(async function () {
      if (!this.formLabelAlign.password) {
        return this.$message({
          message: this.$t("login.login_notice"),
          type: "warning",
        });
      }
      // let password = Base64.encode(this.formLabelAlign.password) // base64加密
      if(this.checked){
        localStorage.setItem('account',this.formLabelAlign.account)
        localStorage.setItem('password',this.formLabelAlign.password)
      }else{
        localStorage.removeItem('account')
        localStorage.removeItem('password')
      }
      const res = await login({
        email: this.formLabelAlign.account,
        password: this.formLabelAlign.password,
      });
      console.log("登录", res);
      const _this = this;
      if (res.code == 1) {
        this.$message({
          message: this.$t("common.loginInfo"),
          type: "success",
          onClose() {
            _this.dialogVisible = false;
            _this.userinfo = res.data.userinfo;
            _this.formLabelAlign.password = "";
            _this.token = res.data.userinfo.token;
            localStorage.setItem("token", res.data.userinfo.token);
            localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
            setTimeout(() => {
              _this.$router.go(0)
            }, 500);
          },
        });
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
          onClose() {
            _this.formLabelAlign.password = "";
          },
        });
      }
    }),

    handleDetail() {
      console.log(this.$route);
      this.title = this.$route.meta.title;
      this.route = this.$route.fullPath;
    },
    toRegister() {
      this.dialogVisible = false;
      this.$router.push({
        name: "register",
      });
    },
    toFindpsw() {
      this.dialogVisible = false;
      this.$router.push({
        name: "findpsw",
      });
    },
    //切换语言
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      console.log("$i18n.locale", this.$i18n.locale);
      localStorage.setItem("language", lang);

      this.$router.go(0);
    },
  },
  created() {
    this.token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
  },
  mounted() {
    EventBus.$on("show-login-dialog", () => {
      this.dialogVisible = true;
    });
    EventBus.$on("show-user-info", () => {
      this.token = localStorage.getItem("token")
        ? localStorage.getItem("token")
        : "";
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    });
  },

  beforeDestroy() {
    EventBus.$off("show-login-dialog");
  },
};
</script>
<style scoped lang="less">
.logininner {
  color: #333333;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  .span1 {
    color: #999999;
    width: 50%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  &:hover {
    color: #628ba3;
    border-bottom: 1px solid #628ba3;
  }
}
.container {
  width: 100%;
  height: 126px;
  .header {
    width: 1200px;
    height: 86px;
    line-height: 86px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    img {
      vertical-align: middle;
    }
    .nav {
      display: flex;
      width: 535px;
      justify-content: space-between;
      li {
        line-height: 3;
        a {
          font-size: 19px;
          color: #333333;
          font-weight: 600;
        }
        &:hover {
          a {
            color: #628ba3;
            border-bottom: 2px solid #628ba3;
          }
        }
        .router-link-active {
          color: #628ba3;
          border-bottom: 2px solid #628ba3;
        }
      }
    }
    .internation {
      display: flex;
      width: 200px;
      justify-content: space-around;
      line-height: 86px;
      &:hover {
        .lng {
          display: block;
        }
      }
      .login {
        color: #628ba3;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        .router-link-active {
          color: #628ba3;
          border-bottom: 2px solid #628ba3;
        }
      }
      .lng {
        display: none;
        background: #fff;
        position: relative;
        z-index: 99999;
        div {
          line-height: 50px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #555555;

          cursor: pointer;
          &:hover {
            color: #628ba3;
            text-decoration: underline;
          }
        }
      }
      .earth {
        width: 50px;
        height: 100%;
        // border: 1px solid;
        text-align: center;
        // margin-left: 20px;
        border-bottom: 2px solid #628ba3;
      }
    }
  }
  .breadcrumb {
    width: 1200px;
    height: 40px;
    border: 1px solid;
    margin: 0 auto;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0px 15px;
    background-color: #628ba3;
    color: white;
    font-size: 14px;
    cursor: pointer;
    /deep/ .el-breadcrumb {
      line-height: inherit;
    }
    .el-icon-s-home:before {
      color: white;
    }
  }
}

.loginContainer {
  width: 100%;
  height: 500px;
  background-color: #fff;
  padding: 30rpx !important;
  box-sizing: border-box;
}
/deep/ .el-dialog__header {
  text-align: center;
  // font-weight: 600;
  display: none;
  font-size: 32px;
  padding: 50px 20px 10px;
}

/deep/ .el-button--primary {
  background-color: #628ba3;
  border-color: #628ba3;
}
/deep/ .el-input__inner {
  background-color: #f2f2f2;
  border: none;
  height: 50px;
  border-radius: 0px;
}
/deep/ .el-button {
  width: 160px;
  height: 54px;
  display: block;
  margin-left: 0px;
  margin-right: 25px;
  margin-top: 22px;
}
/deep/ .el-dialog__title {
  font-size: 26px;
}
.login-header {
  box-sizing: border-box;
  padding-top: 20px;
  text-align: center;
  position: relative;
  background-color: white;
  padding-bottom: 30px;
}
.login-header h1 {
  font-size: 30px;
  letter-spacing: 0px;
  color: #628ba3;
  margin-bottom: 0px;
}
.login-header p {
  font-size: 24px;
  color: #628ba3;
  margin-top: 10px;
  opacity: 0.6;
}
.login-header .exit {
  position: absolute;
  right: 20px;
  top: 20px;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
.loginContainer {
  background-color: #eff3f6;
  display: flex;
  flex-direction: column;
}
.login-main {
  background-color: #eff3f6;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 30px 45px;
}
.el-form-item {
  width: 550px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 4px;

  margin-bottom: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 20px;
  &:nth-child(1) {
  }
}
.active {
  border-left: 6px solid #628ba3;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding-bottom: 0px;
}
/deep/ [data-v-9d1d3a2e] .el-input__inner {
  background-color: white;
  margin-top: -20px;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
  height: 40px;
  padding-left: 0px;
  &::placeholder {
    font-weight: 500;
    font-size: 16px;
  }
}
/deep/ .el-button--text {
  // padding: 0;
  margin: 0;
  text-align: right;
  color: #787878;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #628ba3;
}
/deep/ .el-input__inner{
  background-color: white !important;
  background-color: white;
  margin-top: -20px;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
  height: 40px;
  padding-left: 0px;
  &::placeholder {
    font-weight: 500;
    font-size: 16px;
  }
}
</style>

