export const Debounce = (fn, wait) => {
    let delay = wait|| 500
    let timer
    return function () {
        let args = arguments;
        if (timer) {
            clearTimeout(timer)
        }
       
        let callNow = !timer
        timer = setTimeout(() => {
            timer = null
        }, delay)
        if (callNow) fn.apply(this, args)
    }
}

export const  throttle= (fn, delay)=> {
	let timer = true;
	return function(args) {
		let that = this;
		let _args = arguments;
		if(!timer){
		   return false;
		}
		timer = false;
		setTimeout(() => {
			fn.apply(that, _args)
			timer = true;
		}, delay)
	}
}