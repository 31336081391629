import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'layout',
    redirect: '/search',
    component: () => import(/* webpackChunkName: "layout" */ '../views/layout.vue'),
    children: [
      {
        path: '/about',
        name: 'about',
        meta:{
          title:'header.About'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },
      {
        path:'/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
        meta:{
          title:'header.Home'
        },
      },
      {
        path:'/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "home" */ '../views/login.vue'),
        meta:{
          title:'登录/注册'
        },
      },
      {
        path: '/dataBase',
        name: 'dataBase',
        meta: {
          title: 'header.Name'
        },
        component: () => import(/* webpackChunkName: "dataBase" */ '../views/DataBase.vue'),
       
      },
      {
        path: 'dataDetail',
        name: 'dataDetail',
        meta: {
          title: 'header.dataDetail'
        },
        component: () => import(/* webpackChunkName: "dataBase" */ '../views/DataDetail.vue')
      },
      {
        path:'/search',
        name: 'search',
        meta:{
          title:'header.search'
        },
        component: () => import(/* webpackChunkName: "search */ '../views/Search.vue')
      },{
        path:'/upload',
        name: 'upload',
        meta:{
          title:'header.search'
        },
        component: () => import(/* webpackChunkName: "upload  */ '../views/UploadData.vue')
      },{
        path:'/register',
        name: 'register',
        meta:{
          title:'header.regsiter'
        },
        component: () => import(/* webpackChunkName: "upload  */ '../views/register.vue')
      },{
        path:'/findpsw',
        name: 'findpsw',
        meta:{
          title:'header.findpsw'
        },
        component: () => import(/* webpackChunkName: "upload  */ '../views/findpsw.vue')
      },{
        path:'/history',
        name: 'history',
        meta:{
          title:'header.personal'
        },
        component: () => import(/* webpackChunkName: "upload  */ '../views/history.vue')
      }
]
  }
  , {
  path: '/404',
    name: 'NotFound',
      meta: {
    title: 'Page not found',
      isLogin: false
  },
  component: () => import('../views/notFind.vue')

}
  ,
{
  path: '*',
    redirect: '/404'
}
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
