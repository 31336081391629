<template>
  <div class="container">
    <div class="left">

    </div>
    <div class="right"></div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      
    }
  },
  computed: {},
  watch: {},
  methods: {
    
  },
  created() {
    
  },
  mounted() {
    
  },
  beforeCreate() {}, 
  beforeMount() {}, 
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang='less'>
  .container{
    width: 1200px;
    min-height: 600px;
    border: 1px solid red;
    margin:  20px auto;
    box-sizing: border-box;
  }
</style>