<template>
  <div class="container">
    <div class="contnet">
                             
      <div>
        © 2023 - 2043 
        版权所有 <a target="_blank" style="color:#fff" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2023016493号-1</a>　川公网安备110402500047号　网站标识码bm466000002
      </div>
      <div>
        地址：四川省成都市天府新经济产业园B6号楼13层、天津市南开区天津科技广场5号楼2101  
      </div>
      <div>
        邮编：610000    电话： 18601377686  编辑部邮箱：quqiuhong114@126.com
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  background-image: linear-gradient(#628ba3, #628ba3),
    linear-gradient(#628ba3, #628ba3);
  background-blend-mode: normal, normal;
  .contnet {
    width: 1200px;
    margin: 20px auto;
    text-align: center;
    color: #ffffff;
    box-sizing: border-box;
    // padding: 40px 0px;
  }
}
</style>