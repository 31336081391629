const en = {
  common: {
    none: "none",
    SampleName: "Sample",
    DispersantName: "Dispersant",
    Formula: "Molecular Formula",
    title:"Terahertz spectral database",
    MolecularStructure: "Molecular  Structure",
    CharacteristicPeakPosition: "Characteristic Peak",
    SampleStatus: "Sample State",
    Humidity: "Humidity",
    SampleProvider: "Sample Provider",
    SampleProvider1: "Sample Provider",
    References:'Associated Publications',
    device:'Device',
    remark:'Appendix',
    welcome:'Welcome!',
    SampleClassify:'SampleClassify',
    SpectrogramImage:'Spectrogram Image',
    RecordImage:'Record Image',
    Next:'Next',
    Comfirm:'Comfirm',
    Mobile:'Mobile',
    Email:'Email',
    QualityScore:'Mass Fraction',
    Absorptivity:' Absorption Coefficient',
    RefractiveIndex:'Refractive Index',
    Explain:'Explain',
    HistoricalData:'HistoricalData',
    Private:'Private',
    Select:'Select',
    Open:'Open',
    upload:'Click Upload file',
    drag:'Or drag and drop to upload',
    Notyet:'Not yet',
    please:'Please enter',
    phoneReg1:'Please enter your mobile phone number',
    phoneReg:'The format of the mobile phone number is incorrect',
    email1:'Please enter your email address',
    email:'The mailbox is incorrectly formatted',
    addMsg:'The addition was successful',
    uploadSuccess:'The upload is successful',
    loginInfo:'Login successful',
    notice:{
      searchSuccess:'Search success',
      search:'Fill out at least one search field',
        warning:'Please complete the form',
        SampleName:'Please enter sample name'
    },
    placeholder:{
      SampleName:'Please enter sample name',
      DispersantName:'Please enter the dispersant name',
      Formula:'Please enter the molecular formula',
      MolecularStructure:'Please enter the molecular structure',
      CharacteristicPeakPosition:'Please enter the characteristic peak location',
      SampleStatus:'Please enter sample status',
      Humidity:'Please enter humidity',
      SampleProvider:'Please input sample provider',
      SampleProvider1:'Please input sample provider',
      device:'Please enter the name of the test device',
      SampleClassify:'Please enter sample classification' ,
      SpectrogramImage:'Please enter spectrogram',
      RecordImage:'Please upload data',
      Mobile:'Please enter telephone number',
      Email:'Please enter email',
      open:'Please select whether to make it public'
    }
  },
  error:{
    401:'Unauthorized, please log in again',
    400:'The request is incorrect',
    403:'Access denied',
    404:'The request went wrong',
    408:'The request timed out',
    501:'The server is abnormal',
    502:'There is a problem with your network, please wait',
    504:'Network timeout',
    default:'Failed to connect to the server!',

  },
  search: {
    name: "Terahertz spectral database",
    search: "Search",
    add: "Add",
    submit: "Submit",
    clear: "Clear",
    notice: "Fill out at least one search field",
    exit: "Exit",
    home: "Home",
    goods: "Sample Information",
  },
  login: {
    login: "Login",
    register: "Register",
    title: "Terahertz Spectrum Database",
    notice: "No account yet",
    toRegister: "Sign Up",
    email: "Email Address",
    password: "Password",
    Remember:'Remember me',
    findPsw: "Forget Password？",
    login_notice: "The password cannot be empty",
    email_notice: "The format of the mailbox number is incorrect",
  },
  register: {
    login: "Login",
    register: "Register",
    title: "THZ spectral database  account register",
    notice: "Already have an account",
    toRegister: "Forgot password>",
    email: "Email",
    emailCode:'EmailCode',
    sendCode:'SendCode',
    password: "Password",
    second:'s',
    Invitation:'InvitationCode',
    login_notice: "The password cannot be empty",
    email_notice: "The mailbox number is in the wrong format",
    Code_notice: "The verification code cannot be empty",
    Invitation_notice: "The invitation code cannot be empty",
    success: "The delivery is successful, please go to the mailbox to view",

  },
  Find: {
    login: "Login",
    register: "Register",
    title: "Retrieve your password",
    notice: "Already have an account",
    toRegister: "Forgot password>",
    email: "Email",
    emailCode:'EmailCode',
    sendCode:'SendCode',
    password: "Password",
    second:'s',
    Invitation:'InvitationCode',
    login_notice: "The password cannot be empty",
    email_notice: "The mailbox number is in the wrong format",
    Code_notice: "The verification code cannot be empty",
    Invitation_notice: "The invitation code cannot be empty",
    success: "The delivery is successful, please go to the mailbox to view",
    success1:'The password is reset successfully',
    submit:'Confirm the submission',
    different:'The password is inconsistent twice'

  },
  header: {
    search: "Search",
    Name: "Database",
    upload: "Upload",
    About: "About",
    home: "Home",
    dataDetail: "Data Detail",
    regsiter: "Regsiter",
    findpsw: "RetrievePassword",
    personal:'Personal'
  },
  message:{
    length:'The value ranges from 1 to 15 characters',
    Math:"Only digits and decimals can be entered",
    Email:'The mailbox format is incorrect'
  },
  router: {
    search: "Search",
    Name: "Databa se",
    upload: "Upload",
    About: "About",
    home: "Home",
  },
};

export default en;
