import request from '@/utils/request'
//登录
export function login(data){
    return request({
        url:'/User/login',
        method:'post',
        data
    })
}
//发送邮件
export function send_email(data){
    return request({
        url:'/Ems/send',
        method:'post',
        data
    })
}
//上传
export function uploadfn(data){
    return request({
        url:'/index/upload',
        method:'post',
        data
    })
}
//搜索
export function search(data){
    return request({
        url:'/index/search',
        method:'post',
        data
    })
}
//分类

export function classify(data){
    return request({
        url:'/index/sample_classify',
        method:'post',
        data
    })
}
//添加
export function addition(data){
    return request({
        url:'/index/sample_add',
        method:'post',
        data
    })
}
//添加
export function resetpwd(data){
    return request({
        url:'/User/resetpwd',
        method:'post',
        data
    })
}
//注册
export function register(data){
    return request({
        url:'/User/email_register',
        method:'post',
        data
    })
}
//历史记录
export function history(data){
    return request({
        url:'/Index/history',
        method:'post',
        data
    })
}
//列表
export function data_list(data){
    return request({
        url:'/Index/default_sample_list',
        method:'post',
        data
    })
}
//详情
export function data_details(data){
    return request({
        url:'/Index/sample_details',
        method:'post',
        data
    })
}
//关于我们
export function aboutMe(data){
    return request({
        url:'/Index/about_us',
        method:'post',
        data
    })
}
//搜索条件
export function searchType(data){
    return request({
        url:'/index/condition',
        method:'post',
        data
    })
}