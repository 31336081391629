import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import locale from "element-ui/lib/locale/lang/en";
//自动化注册
import "@/utils/components-reg.js";

import VueI18n from "vue-i18n";
import zh from "@/i18n/config/zh";
import en from "@/i18n/config/en";
Vue.use(VueI18n);

// 自动设置多语言 默认选择浏览器系统语言
const navLang = navigator.language; //判断当前浏览器使用的语言
const localLang = navLang === "zh-CN" || navLang === "en-US" ? navLang : false;
let lang = localStorage.getItem("language") || localLang || "en-US"; //如果有缓存，取缓存的语言
const i18n = new VueI18n({
  locale: lang,
  messages: {
    zh: zh, // 中文语言包
    en: en, // 英文语言包
  },
  silentTranslationWarn: true,
});
localStorage.setItem("language", lang); //最后缓存当前使用的语言

Vue.config.productionTip = false;
import less from "less";
Vue.use(less);
Vue.use(ElementUI, { locale });
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
const requireComponent = require.context(
  // 其组件目录的相对路径
  "./components",
  // 是否查询其子目录
  false,
  // 匹配基础组件文件名的正则表达式
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName);
  // 获取组件的 PascalCase 命名
  const componentName = upperFirst(
    camelCase(
      // 获取和目录深度无关的文件名
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  // 全局注册组件
  Vue.component(componentName, componentConfig.default || componentConfig);
});
