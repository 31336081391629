const zh = {
    common:{
        none:'暂无',
        SampleName:'样品名称',
        DispersantName:'分散剂名称',
        Formula:'分子式',
        MolecularStructure:'分子结构',
        CharacteristicPeakPosition:'特征峰位置',
        SampleStatus:'样品状态',
        Humidity:'湿度',
        SampleProvider:'样品提供者',
        SampleProvider1:'样品提供单位',
        References:'参考文献',
        device:'测试设备',
        welcome:'欢迎访问',
        remark:'备注',
        SampleClassify:'样品分类' ,
        SpectrogramImage:'光谱图',
        RecordImage:'数据上传',
        Mobile:'电话',
        Email:'邮箱',
        QualityScore:'质量分数',
        Next:'下一步',
        Absorptivity:'吸收系数',
        RefractiveIndex:'折射率',
        HistoricalData:'历史数据',
        Comfirm:'确认',
        Explain:'说明',
        Select:'请选择',
        Private:'不公开',
        title:"太赫兹光谱数据库",
        Open:'公开',
        upload:'点击上传文件',
        drag:'或者拖拽上传',
        Notyet:'暂无',
        please:"请输入",
        phoneReg1:'请输入手机号',
        phoneReg:'手机号格式不正确',
        email1:'请输入邮箱',
        email:'邮箱格式不正确',
        addMsg:'添加成功',
        uploadSuccess:'上传成功',
        loginInfo:'登录成功',
        notice:{
            searchSuccess:'搜索成功',
            search:'至少填写一项搜索栏目',
            warning:'请完成表单填写',
            SampleName:'请输入样品名称'
        },
        placeholder:{
          SampleName:'请输入样品名称',
          DispersantName:'请输入分散剂名称',
          Formula:'请输入分子式',
          MolecularStructure:'请输入分子结构',
          CharacteristicPeakPosition:'请输入特征峰位置',
          SampleStatus:'请输入样品状态',
          Humidity:'请输入湿度',
          SampleProvider:'请输入样品提供者',
          SampleProvider1:'请输入样品提供单位',
          device:'请输入测试设备名称',
          SampleClassify:'请输入样品分类' ,
          SpectrogramImage:'请上传光谱图',
          RecordImage:'请上传数据',
          Mobile:'请输入电话',
          Email:'请输入邮箱',
          open:'请选择是否公开'
        },
        message:{
          length:'长度在 1 到 15 个字符',
          Math:"只能输入数字及小数",
          Email:'邮箱格式不正确'
        }
    },
    error:{
      401:'未授权，请重新登录',
      400:'请求错误',
      403:'拒绝访问',
      404:'请求出错',
      408:'请求超时',
      501:'服务器异常',
      502:'您的网络有问题，请稍等',
      504:'网络超时',
      default:'连接服务器失败！',
 
    },
  search: {
    name: "太赫兹光谱数据库",
    search: "数据搜索",
    add: "添加",
    submit: "搜索",
    clear: "清空",
    notice: "至少填写一项搜索栏目",
    exit: "退出",
    home: "首页",
    goods:'样品信息'
  },
  login: {
    login: "登录",
    register: "注册",
    title: "太赫兹光谱数据库",
    notice: "还没有账号",
    toRegister: "注册",
    email: "邮箱",
    password: "密码",
    Remember:'记住密码',
    findPsw: "忘记密码？",
    login_notice: "密码不能为空",
    email_notice: "邮箱号格式错误",
  },
  register: {
    login: "登录",
    register: "注册",
    title: "太赫兹光谱数据库账号注册",
    notice: "已有账号，",
    toRegister: "忘记密码>",
    email: "邮箱",
    emailCode:'邮箱验证码',
    sendCode:'发送验证码',
    password: "密码",
    login_notice: "密码不能为空",
    Code_notice: "验证码不能为空",
    second:'秒',
    Invitation:'邀请码',
    email_notice: "邮箱号格式错误",
    Invitation_notice: "邀请码不能为空",
    success:'发送成功,请前往邮箱查看'
  },
  Find: {
    login: "登录",
    register: "注册",
    title: "找回密码",
    notice: "已有账号，",
    toRegister: "忘记密码>",
    email: "邮箱",
    emailCode:'邮箱验证码',
    sendCode:'发送验证码',
    password: "密码",
    login_notice: "密码不能为空",
    Code_notice: "验证码不能为空",
    second:'秒',
    Invitation:'邀请码',
    email_notice: "邮箱号格式错误",
    Invitation_notice: "邀请码不能为空",
    success:'发送成功,请前往邮箱查看',
    success1:'重置密码成功',
    submit:'确认提交',
    different:'两次密码不一致'
  },
  header: {
    search: "数据搜索",
    Name: "太赫兹光谱数据库",
    upload: "上传数据",
    About: "关于我们",
    home: "首页",
    dataDetail:'数据详情',
    regsiter:'注册',
    findpsw:'找回密码',
    personal:'个人中心'
  },
  router:{
    search: "Search",
    Name: "Databa se",
    upload: "Upload",
    About: "About",
    home: "Home",
   
  }
  
};
export default zh;
